// $(document).ready(function () {
//     $('.slider').slick({
//         slidesToShow: 3, // Default number of slides to show
//         slidesToScroll: 1, // Default number of slides to scroll
//         autoplay: true,
//         autoplaySpeed: 3000,
//         dots: false,
//         prevArrow: '<button class="slick-prev">Prev</button>',
//         nextArrow: '<button class="slick-next">Next</button>',
//         responsive: [
//             {
//                 breakpoint: 1024, // for screens smaller than 1024px
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 1,
//                     infinite: true,
//                     dots: true
//                 }
//             },
//             {
//                 breakpoint: 768, // for screens smaller than 768px
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 1
//                 }
//             },
//             {
//                 breakpoint: 480, // for screens smaller than 480px
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1
//                 }
//             }
//         ]
//     });
// });

// // $(document).ready(function() {
// //     var $container = $('#container-fluid');
// //     var $slider = $('#sl-to-slide .slider');
// //     var isCursorOverSlider = false;

// //     $('#sl-to-slide').on('mouseenter', function() {
// //         console.log("ok")
// //         isCursorOverSlider = true;
// //     });

// //     $('#sl-to-slide').on('mouseleave', function() {
// //         isCursorOverSlider = false;
// //     });

// //     $container.on('wheel', function(event) {
// //         if (isCursorOverSlider) {
// //             event.preventDefault();
// //             $slider.scrollLeft($slider.scrollLeft() + event.originalEvent.deltaY);
// //         }
// //     });
// // });

// // $(document).ready(function() {
// //     var $section = $('#container-fluid');
// //     var $div2 = $('#sl-to-slide');
// //     var $slider = $div2.find('.slider');
// //     var isCursorOverDiv2 = false;

// //     // $div2.on('mouseenter', function() {
// //     //     isCursorOverDiv2 = true;
// //     //     console.log("di2 enter")
// //     // });

// //     // $div2.on('mouseleave', function() {
// //     //     isCursorOverDiv2 = false;
// //     // });

// //     $section.on('wheel', function(event) {
// //         console.log("wheel")
// //         let scrollLeft = $slider.scrollLeft();
// //         let scrollWidth = $slider[0].scrollWidth;
// //         clientWidth = $slider[0].clientWidth;
        
// //         let maxScrollLeft = scrollWidth - clientWidth;
// //         console.log();
// //         // console.log($slider);
// //         // console.log("scrollLeft: "+scrollLeft+ " scrollWidth: "+ scrollWidth+" maxScrollLeft: "+maxScrollLeft);
// //         if (event.originalEvent.deltaY < 0 && scrollLeft > 0) {
// //             event.preventDefault();
// //             $slider.scrollLeft(scrollLeft + event.originalEvent.deltaY);
// //         }
// //         else if (event.originalEvent.deltaY > 0 && scrollLeft < maxScrollLeft) {
// //             event.preventDefault();
// //             $slider.scrollLeft(scrollLeft + event.originalEvent.deltaY);
// //         }
// //         else {
// //         }
// //         // let scrollLeft = $slider.scrollLeft();
// //         // let scrollWidth = $slider.scrollWidth(),
// //         // clientWidth = $slider.clientWidth();
        
// //         // let maxScrollLeft = scrollWidth - clientWidth;


// //         // console.log("maxScrollLeft: "+ maxScrollLeft+ " scrollLeft: "+scrollLeft+ " scrollWidth: "+ scrollWidth)
// //         // if((scrollLeft === 0 && event.originalEvent.deltaY < 0) && !(scrollLeft >= maxScrollLeft && event.originalEvent.deltaY > 0)){

// //             // if (isCursorOverDiv2) {
// //                 // console.log("wheel-1")
// //                 // event.preventDefault();
// //                 // $slider.scrollLeft($slider.scrollLeft() + event.originalEvent.deltaY);
// //                 // }
// //             // }

// //          // if (isCursorOverDiv2) {
// //             // console.log("wheel-2")
// //             // event.preventDefault();
// //             // $slider.scrollLeft($slider.scrollLeft() + event.originalEvent.deltaY);
// //             // }
// //     });
// // });

// // section 13 animation
// $(document).ready(function(){
//     $('.analyse-article').click(function(){
//         // console.log("...")
//         let name = $(this).data('name');
//         console.log(name);
//         $('.analyse-article').removeClass('active');
//         $(this).addClass('active');
//         // let name = $('.analyse-article-details').data('name');

//         $('.analyse-article-details').removeClass('active');
//         $('.analyse-article-details[data-name="'+ name +'"]').addClass('active');
        
//     })
// })







// // $(document).ready(function (){
// // //     gsap.registerPlugin(ScrollTrigger);

// // // let sections = gsap.utils.toArray(".panel");

// // // gsap.to(sections, {
// // //   xPercent: -100 * (sections.length - 1),
// // //   ease: "none",
// // //   scrollTrigger: {
// // //     trigger: ".container-panel",
// // //     pin: true,
// // //     scrub: 1,
// // //     snap: 1 / (sections.length - 1),
// // //     end: () => "+=" + document.querySelector(".container-panel").offsetWidth
// // //   }
// // // });


// // // document.addEventListener("DOMContentLoaded", function() {
// // //     const container = document.querySelector('.container-panel');
// // //     const panels = document.querySelectorAll('.panel');
// // //     const panelWidth = panels[0].offsetWidth;
// // //     const numOfPanels = panels.length;
// // //     const totalPanelWidth = panelWidth * numOfPanels;
// // //     const maxScrollY = document.body.scrollHeight - window.innerHeight;
// // //     const maxScrollX = totalPanelWidth - window.innerWidth;

// // //     window.addEventListener('scroll', function() {
// // //         const scrollY = window.scrollY;
// // //         const scrollRatio = scrollY / maxScrollY;
// // //         const targetScrollX = scrollRatio * maxScrollX;
// // //         container.style.transform = `translateX(-${targetScrollX}px)`;
// // //     });
// // // });


// // });

// // $(document).ready(function() {
// //     $(window).on('scroll', function() {
// //         var scrollTop = $(this).scrollTop();
// //         $('.container-panel').scrollLeft(scrollTop);
// //     });

// //     // Function to enable horizontal scrolling
// //     function enableHorizontalScrolling() {
// //         $(window).on('scroll', function() {
// //             var scrollTop = $(this).scrollTop();
// //             $('.container-panel').scrollLeft(scrollTop);
// //         });
// //     }

// //     // Function to check if we are on the specific section
// //     function checkSection() {
// //         var sectionTop = $('.fluid-slider-contain').offset().top;
// //         var sectionBottom = sectionTop + $('.fluid-slider-contain').outerHeight();
// //         var windowTop = $(window).scrollTop();
// //         var windowBottom = windowTop + $(window).height();

// //         // Check if the window's top or bottom is within the section's boundaries
// //         if (windowTop < sectionBottom && windowBottom > sectionTop) {
// //             enableHorizontalScrolling();
// //         } else {
// //             $(window).off('scroll');
// //         }
// //     }

// //     // Check the section on scroll and on document ready
// //     $(window).on('scroll', checkSection);
// //     checkSection();

// // });


// // ---------
// // $(document).ready(function() {
// //     // Function to enable horizontal scrolling
// //     function enableHorizontalScrolling() {
// //         $(window).on('scroll.horizontalScroll', function() {
// //             var scrollTop = $(this).scrollTop();
// //             $('.container-panel').scrollLeft(scrollTop);
// //         });
// //     }

// //     // Function to disable horizontal scrolling
// //     function disableHorizontalScrolling() {
// //         $(window).off('scroll.horizontalScroll');
// //     }

// //     // Function to check if we are on the specific section
// //     function checkSection() {
// //         var sectionTop = $('.fluid-slider-contain').offset().top;
// //         var sectionBottom = sectionTop + $('.fluid-slider-contain').outerHeight();
// //         var windowTop = $(window).scrollTop();
// //         var windowBottom = windowTop + $(window).height();

// //         // Check if the window's top or bottom is within the section's boundaries
// //         if (windowBottom > sectionTop && windowTop < sectionBottom) {
// //             enableHorizontalScrolling();
// //         } else {
// //             disableHorizontalScrolling();
// //         }
// //     }

// //     // Check the section on scroll and on document ready
// //     $(window).on('scroll', checkSection);
// //     checkSection();
// // });



// // ----------
// // $(document).ready(function() {
// //     var panelWidth = $('.slide-wrap').outerWidth(true); // Width of each panel including margin
// //     var panelsCount = $('.slide-wrap').length; // Total number of panels
// //     var containerWidth = panelWidth * panelsCount; // Total width of the container

// //     // Set the width of the container to fit all panels horizontally
// //     $('.container-panel').css('width', containerWidth);

// //     var col8Top = $('.fluid-slider-contain').offset().top;
// //     var col8Bottom = col8Top + $('.fluid-slider-contain').outerHeight();
// //     var scrollingEnabled = false;

// //     // Function to enable/disable horizontal scrolling based on vertical scroll position
// //     function checkScroll() {
// //         var scrollTop = $(window).scrollTop();
// //         var windowBottom = scrollTop + $(window).height();

// //         if (windowBottom > col8Top && scrollTop < col8Bottom) {
// //             enableHorizontalScrolling();
// //         } else {
// //             disableHorizontalScrolling();
// //         }
// //     }

// //     // Function to enable horizontal scrolling
// //     function enableHorizontalScrolling() {
// //         if (!scrollingEnabled) {
// //             $('.container-panel').on('scroll', scrollHorizontally);
// //             scrollingEnabled = true;
// //         }
// //     }

// //     // Function to disable horizontal scrolling
// //     function disableHorizontalScrolling() {
// //         if (scrollingEnabled) {
// //             $('.container-panel').off('scroll');
// //             scrollingEnabled = false;
// //         }
// //     }

// //     // Function to scroll horizontally through panels
// //     function scrollHorizontally() {
// //         var scrollLeft = $(this).scrollLeft();
// //         var panelIndex = Math.round(scrollLeft / panelWidth); // Round to nearest panel index
// //         var targetScrollLeft = panelIndex * panelWidth;

// //         $(this).stop().animate({
// //             scrollLeft: targetScrollLeft
// //         }, 300);
// //     }

// //     // Check scroll position on page load and scroll
// //     $(window).on('scroll', function() {
// //         checkScroll();
// //     });
// //     checkScroll();
// // });
