$(document).ready(function () {
    // $(window).scroll(function (event) {
    //     var scroll = $(window).scrollTop();
    //     $(".form-wrap").css("top", scroll);
    // })

    $(".full-content .head").click(function () {
        $(".para").slideToggle();
    })

    $(".fd-contact-us").click(function () {
        $("html").addClass("clickpopup");
        $(".form-wrap").css("top", $(window).scrollTop());
    });

    $(".fd-contact-us-1").click(function () {
        $("html").addClass("clickpopup");
        $(".form-wrap").css("top", $(window).scrollTop());
    });
    $(".close").click(function () {
        $("html").removeClass("clickpopup");
    });

});