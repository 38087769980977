// $(document).ready(function () {
//     var isDragging = false;
//     var previousX = 0;
  
//     // called after dropping animation is completed
//     function onDrop() {
//       // $('.drop').css({ 'background': '#ff5722' })
//       $('.drop').prepend('<img id="theImg" src="./src/images/Vector5.svg" />')
//       $('.step-3-txt').animate({"opacity": "1"}, 1000);
//       $('.step-3-drag').animate({"opacity": "0", "display": "none"}, 1000);
  
//       // window.setTimeout(function () {
//       //   // $('.drop').css({ 'background': 'white' })
//       // }, 2500);
//     }
  
//     // collision detection
//     // source : http://stackoverflow.com/questions/4230029/jquery-javascript-collision-detection
//     function isColliding(x, y, element2) {
//       var e2 = {};
//       var scrollTop = $(window).scrollTop();
  
//       // e2.top = $(element2).offset().top ;
//       e2.top = $(element2).offset().top - scrollTop;
//       e2.left = $(element2).offset().left;
//       e2.right = parseFloat($(element2).offset().left) + parseFloat($(element2).width());
//       // e2.bottom = parseFloat($(element2).offset().top) + parseFloat($(element2).height());
//       e2.bottom = parseFloat($(element2).offset().top) + parseFloat($(element2).height() - scrollTop);
  
//       if (x > e2.left && x < e2.right && y < e2.bottom && y > e2.top) {
//         return true
//       }
//     }
  
//     $('.drag').on('mousedown', function (e) {
//       var isDragging = true;
//       var drag = $(this).clone().addClass('dragged').appendTo('.wrapper');
//       var dropZone = $(this).data('target');
//       var originalPosX = $(this).offset().left;
//       var originalPosY = $(this).offset().top;
  
//       var scrollTop = $(window).scrollTop(),
//       scrollLeft = $(window).scrollLeft();
  
//       //   console.log(" opy "+ originalPosY + "ecy " + e.clientY, "scrtop " + scrollTop)
//       // console.log("opx: " + originalPosX + " clientX: " + e.clientX)
  
//       var startX = e.clientX - originalPosX + scrollLeft;
//       var startY = e.clientY - originalPosY + scrollTop;
  
//       // var startY = e.clientY / 2;
  
//       //   console.log(startX, startY + "positions start x, y  :: opx, opy "+ originalPosX, originalPosY + "ecx,ecy" + e.clientX, e.clientY)
//       //   console.log("positions y  ::" + startY );

//       // drag.css({ 'left': e.clientX - startX + $(window).scrollLeft(), 'top': e.clientY - startY + $(window).scrollTop() });
//       // drag.css({ 'left': $(window).scrollLeft() - 20, 'top': $(window).scrollTop() - 20 });
  
//       // console.log("Math.round(startX / drag.outerWidth() * 100):: "+Math.round(startX / drag.outerWidth() * 100) )
//       // console.log("Math.round(startY / drag.outerHeight() * 100)::"+Math.round(startY / drag.outerHeight() * 100));
      
//       // drag.css({ 'left': e.clientX - startX - originalPosX, 'top': e.clientY - startY })
//       // drag.css({ 'left': originalPosX})

//       drag.css({ 'transform-origin': Math.round(startX / drag.outerWidth() * 100) + '% ' + Math.round(startY / drag.outerHeight() * 100) + '%' })
//       drag.addClass('beginDrag')
  
//       $(window).on('mousemove', function (event) {
//         if (isDragging) {
//           // console.log("moving..")
//           // console.log("clientX: "+ event.clientX + "clientY: "+ event.clientY);
//           // console.log("event.clientX: "+event.clientX+ "startX: "+startX+" originalPosX: "+originalPosX)
          
//           drag.css({ 'left': event.clientX - startX, 'top': event.clientY - startY })
//           // drag.css({ 'left': $(this).offset().left + $(window).scrollLeft(), 'top': $(this).offset().top + $(window).scrollTop() })
//           // drag.css({ 'left': e.clientX - startX + $(window).scrollLeft(), 'top': e.clientY - startY + $(window).scrollTop() });
//           // drag.css({ 'left': e.clientX + $(window).scrollLeft(), 'top': e.clientY - startY + $(window).scrollTop() });
          
//           // drag.css({})
//           if (isColliding(event.clientX, event.clientY, '.drop')) {
//             drag.removeClass('beginDrag')
//             drag.addClass('readyDrop')
//           } else {
//             drag.removeClass('readyDrop')
//             drag.addClass('beginDrag')

//           }
//         }
//       });
//       $(window).on('mouseup', function (event) {
//         if (isDragging) {
//           $(window).off('mousemove');
//           if (isColliding(event.clientX, event.clientY, '.drop')) {
//             // console.log("..animate-1")

//             drag.removeClass('readyDrop').addClass('bye');
//             // console.log("mouseup");
//             window.setTimeout(function () {
//               onDrop()
//               drag.remove()
//             }, 400)
//           } else {
//             // console.log("..animate-2")

//             drag.animate({ 'top': originalPosY, 'left': originalPosX, 'opacity': 0 }, 400, function () {
//               drag.remove()
//             })
//           }
//         // console.log("..animate-3")

//           isDragging = false;
//         }
//       })
//     });
//   })