var $loupesvg = $(".loupe-svg"),
            loupesvgWidth = $loupesvg.width(),
            loupesvgHeight = $loupesvg.height();
        var $loupe = $(".loupe"),
            loupeWidth = $loupe.width(),
            loupeHeight = $loupe.height();


        $(document).on("mouseenter", ".sticky-image", function (e) {
            var $currImage = $(this),
                $img = $('<img/>')
                    .attr('src', $('img', this).attr("src"))
                    // .attr('src', './src/images/sticky-note-1.png')
                    .css({ 'width': $currImage.width() * 2, 'height': $currImage.height() * 2 });

            $loupesvg.css({ "display": "block" }).fadeIn(100);
            $loupe.css({ "background": "white" }).fadeIn(100);
            $loupe.html($img).fadeIn(100);

            $(document).on("mousemove", moveHandler);

            function moveHandler(e) {
                var imageOffset = $currImage.offset(),
                    fx = imageOffset.left - loupeWidth / 2,
                    fy = imageOffset.top - loupeHeight / 2,
                    fh = imageOffset.top + $currImage.height() + loupeHeight / 2,
                    fw = imageOffset.left + $currImage.width() + loupeWidth / 2;

                $loupesvg.css({
                    'left': e.pageX - 90,
                    'top': e.pageY - 100
                });
                $loupe.css({
                    'left': e.pageX - 75,
                    'top': e.pageY - 75
                });

                var loupeOffset = $loupe.offset(),
                    lx = loupeOffset.left,
                    ly = loupeOffset.top,
                    lw = lx + loupeWidth,
                    lh = ly + loupeHeight,
                    bigy = (ly - loupeHeight / 4 - fy) * 2,
                    bigx = (lx - loupeWidth / 4 - fx) * 1.7;
                // bigy = (ly - loupeHeight / 4 - fy) * 2,
                // bigx = (lx - loupeWidth / 4 - fx) * 2;
                console.log("bigy:" + bigy)
                $img.css({ 'left': -bigx, 'top': -bigy });

                if (lx < fx || lh > fh || ly < fy || lw > fw) {
                    // $img.remove();
                    $loupe.fadeOut(100);
                    $loupe.css({ "background": "none" }).fadeOut(100);
                    $loupesvg.css({ "display": "none" }).fadeOut(100);

                    $(document).off("mousemove", moveHandler);

                }
            }
        });