// Nav Links

$('.nav__link').click(function (e) {
    e.preventDefault();
    var target = $($(this).attr('href'));
    if (target.length) {
        var scrollTo = target.offset().top;
        $('body, html').animate({
            scrollTop: scrollTo + 'px'
        }, 1200);
    }
});


// animation of storke svg
document.addEventListener('DOMContentLoaded', function () {

    skrollr.init()
    
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    entry.target.querySelector('.svg-mask').style.strokeDashoffset = '0';
                }, 1000); // 2-second delay
            }
        });
    }, options);


    document.querySelectorAll('.svg-hold').forEach(section => {
        observer.observe(section);
    });

    const observer2 = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    entry.target.querySelector('.svg-mask-2').style.strokeDashoffset = '0';
                }, 3000); // 2-second delay
            }
        });
    }, options);


    document.querySelectorAll('.svg-hold-2').forEach(section => {
        observer2.observe(section);
    });
    
});