$(document).ready(function (){
    let handsvg = $('.hand-svg-card');
    $('.playing-card-uno').hover(
        function() {
            let number = $(this).data('number');
            console.log('Hovered over the applications-of-ai section');
            console.log(number)
            $('.hand-svg-card[data-number="'+ number +'"').show();
            $('.hand-svg').hide();
        },
        function() {
            console.log('Left the applications-of-ai section');
            // $('.hand-svg[data-number="'+ number +'"').show();
            $('.hand-svg-card').hide();
            $('.hand-svg').show();
        }
    );
    // console.log("ok..")    
})


$(document).ready(function () {
    $('.playing-cards-slider').slick({
        slidesToShow: 2, // Default number of slides to show
        slidesToScroll: 1, // Default number of slides to scroll
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        prevArrow: '<button class="slick-prev">Prev</button>',
        nextArrow: '<button class="slick-next">Next</button>',
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]

    });
});