// $(document).ready(function (){
//     console.clear();

//   gsap.registerPlugin(ScrollTrigger);

//   const cards = gsap.utils.toArray(".card");
//   const spacer = 20;

//   cards.forEach((card, index) => {
//     ScrollTrigger.create({
//       trigger: card,
//       start: `top-=${index * spacer} top+=10px`,
//       endTrigger: ".pin-panel",
//       end: `bottom top+=${200 + cards.length * spacer}`,
//       pin: true,
//       pinSpacing: false,
//       markers: true,
//       id: "card-pin",
//       invalidateOnRefresh: true
//     });
//   });

//   ScrollTrigger.create({
//     trigger: ".image",
//     start: "top 10",
//     end: (self) => self.previous().end,
//     pin: true,
//     markers: true
//   });
// })