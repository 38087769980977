import './modules/m1';
import './modules/m3';
import './modules/m4';
import './modules/m6';
import './modules/m7';
import './modules/m8';
import './modules/m9';
import './modules/m10';
import './modules/m12';

import './modules/contact-us-popup';
import './modules/card-scroll';
import './modules/cookie-bar';

// import "./modules/benefits-slider";


// import jquery from './jquery';

// document.onload()