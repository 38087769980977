   $(document).ready(function () {
            // this is just my trigger to play around the animation, will be removed in production
            var stemDuration = 4000;

            $('.trigger').on('click', function () {
                $('.growing-plant-txt').animate({ "opacity": "1" }, 1000);
                $('.growing-plant').addClass("animate");
            })
            $('.latter-image').on('click', function () {
                $('.growing-plant-txt').animate({ "opacity": "1" }, 1000);
                $('.growing-plant').addClass("animate");

            })
        });